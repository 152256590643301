<template>
    <div id="page-loyalty-management-search-voucher">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Search Voucher</h1>

                    <app-error v-model="errors.show" :message="errors.message"></app-error>

                    <app-search-box
                        label="Voucher Code"

                        @search="searchVoucher"
                    />

                    <template v-if="is_voucher_loaded">
                        <div class="voucher">
                            <div class="heading">Description:</div>

                            <div class="wrapper">
                                <p>{{ formatted_voucher.Description }}</p>

                                <div class="details">
                                    <div class="price">{{ formatted_voucher.Price }}</div>

                                    <div class="tick-box" :class="{ 'redeemed': is_voucher_redeemed }">
                                        <div class="label">Redeemed</div>
                                        <i></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="actions" v-if="!is_voucher_redeemed">
                            <button class="btn btn-primary btn-redeem" @click="redeem">Redeem</button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appSearchBox from '@/components/app-search-box'

import errMessage from '@/helpers/errMessage'

import testVoucher from './test-voucher.json'

export default {
    components: {
        appLoader,
        appError,
        appSearchBox,
    },

    data() {
        return {
            voucher: {},
            is_voucher_loaded: false,

            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        searchVoucher(search_query) {
            if (search_query.trim().length) {
                this.loading = true

                const delay_in_ms = 1000

                setTimeout(() => {
                    this.voucher = testVoucher

                    this.is_voucher_loaded = true

                    this.loading = false
                }, delay_in_ms)
            }
        },

        /*
            searchVoucher(search_query) {
                if (search_query.trim().length) {
                    this.loading = true

                    const params = {}

                    console.log('params', params)

                    this.$store.dispatch('', params)
                        .then(response => {
                            console.log('response', response)

                            this.is_voucher_loaded = true

                            this.errors.show = false
                            this.errors.message = ''

                            this.loading = false
                        })
                        .catch(error => {
                            this.errors.show = true
                            this.errors.message = errMessage(error)

                            this.loading = false
                        })
                }
            },
        */

       redeem() {
           this.$router.push({ name: 'loyalty-management-customer-search', params: { voucher_uuid: this.voucher_uuid } })
       },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        formatted_voucher() {
            return {
                Description: this.voucher.Description ? this.voucher.Description : '?',
                Price: `$${ this.voucher.Cents % 100 == 0 ? this.voucher.Cents / 100 : (this.voucher.Cents / 100).toFixed(2) }`,
                Redeemed: this.voucher.Redeemed,
            }
        },

        is_voucher_redeemed() {
            return this.voucher.Redeemed
        },

        voucher_uuid() {
            return this.voucher.UUID
        },
    },
}
</script>

<style lang="scss">
#page-loyalty-management-search-voucher {
    margin-top: 48px;
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .voucher {
        display: flex;

        margin-top: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .heading {
            margin: 0 30px 0 0;

            font-size: 20px;
            line-height: 32px;
        }

        .wrapper {
            width: 100%;

            p {
                margin-bottom: 16px;
            }

            .details {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .price {
                    color: var(--color-table-secondary);
                }

                .tick-box {
                    display: flex;
                    align-items: center;

                    .label {
                        margin-right: 16px;

                        font-size: 20px;
                        line-height: 32px;
                    }

                    i {
                        width: 24px;
                        height: 24px;
                        color: var(--color-table-secondary);

                        @include icon-before($icon-checkbox-partially-checked);
                    }

                    &.redeemed {
                        i {
                            color: var(--color-btn-primary-bg);

                            @include icon-before-code($icon-checkbox-checked);
                        }
                    }
                }
            }
        }
    }

    .actions {
        display: flex;
        justify-content: center;

        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    #page-loyalty-management-search-voucher {
        .voucher {
            .heading {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-loyalty-management-search-voucher {
        margin-top: 32px;
        padding-bottom: 64px;

        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .voucher {
            flex-direction: column;

            margin-top: 15px;
            padding: 16px 8px;

            .heading {
                margin: 0 0 8px 0;

                font-size: 16px;
            }

            .wrapper {
                p {
                    font-size: 14px;
                }

                .details {
                    .price {
                        font-size: 14px;
                    }

                    .tick-box {
                        .label {
                            margin-right: 8px;

                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        .actions {
            .btn-redeem {
                max-width: 100%;
            }
        }
    }
}
</style>